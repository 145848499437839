import wx from 'weixin-js-sdk';
import http from './http';
import consts from './consts';


let {SHAREOPT} = consts;
export const dateFormat = (timestamp) => {
  let date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  return Y + M + D;
}

export const timeFormat = (timestamp) => {
  let date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
  let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes());
  return h + m;
}
export const dayFormat = (timestamp) => {
  let date = new Date(timestamp * 1000);
  let week = '';
  switch (date.getDay()) {
    case 0:
      week = "星期天";
      break
    case 1:
      week = "星期一";
      break
    case 2:
      week = "星期二";
      break
    case 3:
      week = "星期三";
      break
    case 4:
      week = "星期四";
      break
    case 5:
      week = "星期五";
      break
    case 6:
      week = "星期六";
      break
  }
  return week;
}

export const getQueryString = (name) => {
  const url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const wxConfig = () => {
  let url = location.href.indexOf('#') > -1 ? location.href.split('#')[0] : location.href;
  http.request('GET', '/api/jssdk', {url}).then(res => {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: res.appId, // 必填，公众号的唯一标识
      timestamp: res.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.nonceStr, // 必填，生成签名的随机串
      signature: res.signature,// 必填，签名，见附录1
      jsApiList: [
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        `updateAppMessageShareData`,
        'updateTimelineShareData',
        'onMenuShareWeibo',
        'onMenuShareQZone',
        'chooseWXPay'
      ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    });
  })
  wx.ready(() => {
    wxShareTimeline();
    wxShareAppMessage();
    wxShareWeibo();
    wxShareQZone();
    console.log('微信设置成功');
  })
  wx.error(() => {
    console.log('微信设置失败')
  })
}
export const wxShareTimeline = () => {
  let {title, imgUrl, link} = SHAREOPT;
  // wx.onMenuShareTimeline({
  //   title, // 分享标题
  //   link, // 分享链接
  //   imgUrl, // 分享图标
  //   success: function () {
  //     // 用户确认分享后执行的回调函数
  //   },
  //   cancel: function () {
  //     // 用户取消分享后执行的回调函数
  //   }
  // });
  // let query = localStorage.getItem('channel');
  // link = link + '?channel=' + query;
  wx.updateTimelineShareData({
    title: title, // 分享标题
    link: link, // 分享链接
    imgUrl: imgUrl, // 分享图标
    success: function () {
      console.log("wxShareTimeline", 'success')
      // 用户确认分享后执行的回调函数
    },
    cancel: function () {
      console.log("wxShareTimeline", 'cancel')
      // 用户取消分享后执行的回调函数
    }
  })
}
export const wxShareAppMessage = () => {
  let {title, imgUrl, link, desc} = SHAREOPT;
  // let query = localStorage.getItem('channel');
  // link = link + '?channel=' + query;
  wx.updateAppMessageShareData({
    title: title, // 分享标题
    desc: desc, // 分享描述
    link: link, // 分享链接
    imgUrl: imgUrl, // 分享图标
    success: function () {
      // 用户确认分享后执行的回调函数
      console.log("wxShareAppMessage", 'success')
    },
    cancel: function () {
      // 用户取消分享后执行的回调函数
      console.log("wxShareAppMessage", 'cancel')
    }
  })
  // wx.onMenuShareAppMessage({
  //   title, // 分享标题
  //   link, // 分享链接
  //   imgUrl, // 分享图标
  //   desc,
  //   success: function () {
  //     // 用户确认分享后执行的回调函数
  //   },
  //   cancel: function () {
  //     // 用户取消分享后执行的回调函数
  //   }
  // });
}

export const wxShareWeibo = () => {
  let {title, imgUrl, link, desc} = SHAREOPT;
  // let query = localStorage.getItem('channel');
  // link = link + '?channel=' + query;
  wx.onMenuShareWeibo({
    title: title, // 分享标题
    desc: desc, // 分享描述
    link: link, // 分享链接
    imgUrl: imgUrl, // 分享图标
    success: function () {
      // 用户确认分享后执行的回调函数
    },
    cancel: function () {
      // 用户取消分享后执行的回调函数
    }
  })
}

export const wxShareQZone = () => {
  let {title, imgUrl, link, desc} = SHAREOPT;
  // let query = localStorage.getItem('channel');
  // link = link + '?channel=' + query;
  wx.onMenuShareQZone({
    title: title, // 分享标题
    desc: desc, // 分享描述
    link: link, // 分享链接
    imgUrl: imgUrl, // 分享图标
    success: function () {
      // 用户确认分享后执行的回调函数
    },
    cancel: function () {
      // 用户取消分享后执行的回调函数
    }
  })
}
