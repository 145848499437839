import axios from 'axios';
import consts from './consts';
import alert from './alert';
import {getQueryString} from "./filter";

const {BASE_URL} = consts;

// http request 拦截器
axios.interceptors.request.use(
  config => {
    if (localStorage.getItem('token')) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.token = localStorage.getItem('token');
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          alert.fail('token过期,正在重新登陆...');
          setTimeout(() => {
            localStorage.removeItem('token');
            let channel = getQueryString('channel');
            if (channel === null || channel === '') {
              channel = localStorage.getItem('channel');
            }
            location.href = "https://np.quick58.com/api/oauth?channel=" + channel;
          }, 1000);
      }
    }
    return Promise.reject(error)   // 返回接口返回的错误信息
  }
);

export default {
  request: (method = 'GET', url, data = {}, resolveCode = false) => {
    const promise = new Promise((resolve, reject) => {
      axios({
        baseURL: BASE_URL,
        method,
        url,
        params: method == 'GET' ? data : {},
        data: method == 'POST' ? data : {},
        withCredentials: true,
        headers: {
          'Access-Control-Allow-Headers': '*',
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if (!resolveCode) {
          if (res.data.retCode == 0) {
            resolve(res.data.retData);
          } else if (res.data.retCode == 2) {
            alert.fail('token过期,正在重新登陆...');
            setTimeout(() => {
              let channel = getQueryString('channel');
              if (channel === null || channel === '') {
                channel = localStorage.getItem('channel');
              }
              location.href = "https://np.quick58.com/api/oauth?channel=" + channel;
            }, 1000);
          } else {
            alert.fail(res.data.retMsg);
          }
        } else {
          resolve(res)
        }
      }).catch(err => {
        alert.hideToast();
        reject(err);
      })
    })
    return promise;
  }
}
