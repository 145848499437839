const HOST = location.host;

let BASE_URL;
let ENV;
if (HOST === 'localhost:3000') {
  ENV = 'DEV';
  BASE_URL = 'https://np.quick58.com'
} else {
  ENV = 'PROD';
  BASE_URL = 'https://np.quick58.com'
}
let SHAREOPT = {
  title: '长红推荐商城', // 分享标题
  desc: '长红推荐商城', // 分享描述
  link: location.href, // 分享链接
  imgUrl: 'https://np.quick58.com/static/share.jpg', // 分享图标
}
export default { BASE_URL, ENV, SHAREOPT };
